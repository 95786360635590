.header {
  line-height:    1;
  letter-spacing: 0px;
  font-size:      2rem;
  font-weight:    600;
}

.header.underline {
  padding-bottom: var(--padding);
}

.header.light.underline {
  border-bottom: 2px solid var(--steel);
}

.header.dark.underline {
  border-bottom: 2px solid #1A1938;
}

.light {
  color: var(--dusk);
}

.dark {
  color: var(--snow);
}
