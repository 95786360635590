div.modal {
  max-height:     80vh;
  max-width:      70vw;
  //min-height:     100px;
  //min-width:      100px;
  position:       fixed;
  z-index:        1001;
  top:            50%;
  left:           50%;
  transform:      translate(-50%, -50%);
  padding:        var(--padding);
  transition:     opacity var(--default-transition);
  border-radius:  var(--border-radius);
  opacity:        0;
  pointer-events: none;
  overflow:       auto;

  &.active {
	opacity:        1;
	pointer-events: all;
  }

  &.dark {
	background: var(--twilight-darker);
  }

  &.light {
	background: var(--snow);
  }
}

div.modal-alt-exit {
  position:       fixed;
  top:            0;
  left:           0;
  z-index:        1000;
  background:     #000000;
  width:          100vw;
  height:         100vh;
  transition:     opacity var(--default-transition);
  cursor:         pointer;
  opacity:        0;
  pointer-events: none;

  &.active {
	opacity:        .5;
	pointer-events: all;
  }
}

