.shell {
	position: relative;
	width: 100vw;
	height: 100vh;
}

.nav {
	height: var(--nav-height);
	width: 100vw;
	position: absolute;
	z-index: var(--nav-zindex);
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	border-radius: 0;

	div.nav-logo {
		height: var(--nav-height);
		width: var(--nav-height);
		background: url("../../../public/logo.png") no-repeat center;
		background-size: 70%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		filter: grayscale(100%);
		opacity: .5;
	}

	.organization-select {
		//height:        25px;
		min-width: 150px;
		max-width: 300px;
		width: auto;
		position: relative;
		left: 60px;
		border-radius: var(--border-radius);
		border: 1px solid var(--twilight);
		color: var(--cloud);
		padding: 0 6px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		transition: background var(--default-transition);

		&.active {
			background: rgba(62, 60, 119, 0.4);
		}

		svg {
			margin-right: 3px;
		}

		p.organization-select-selected {
			color: var(--cloud) !important;
			font-size: .8rem;
			font-weight: 600;
			text-overflow: ellipsis;
			user-select: none
		}
	}

	.organization-select-dropdown {
		max-height: 500px;
		overflow: auto;
		position: absolute;
		top: calc(var(--nav-height) - 20px);
		left: 60px;
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		opacity: 0;
		pointer-events: none;
		transition: opacity var(--default-transition), top var(--default-transition);
		padding: 6px 0;
		box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);

		&.dark {
			background: rgba(62, 60, 119, 0.5);
			backdrop-filter: blur(20px);
			border: 1px solid var(--twilight);
			border-radius: var(--border-radius);

			p {
				color: var(--cloud);
			}
		}

		&.light {
			background: rgba(62, 60, 119, 0.5);
			backdrop-filter: blur(20px);

			p {
				color: var(--snow);
			}
		}

		&.active {
			opacity: 1;
			pointer-events: all;
			top: calc(var(--nav-height) - 14px);
		}

		.item {
			width: 100%;
			height: auto;
			padding: 6px 12px;
			user-select: none;
			cursor: pointer;

			&.disabled {
				background: rgba(255, 255, 255, 0.1) !important;
				cursor: default;
				pointer-events: none;
			}

			p {
				font-size: .8rem;
				font-weight: 600;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.1) !important;
			}
		}
	}

	.nav-profile-icon-wrapper {
		height: var(--nav-height);
		width: var(--nav-height);
		position: absolute;
		right: var(--padding);
		padding: 10px 0 10px 20px;

		.nav-profile-icon {
			height: 100%;
			width: 100%;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			overflow: hidden;
			border: 1px solid var(--cloud);
			background: #101010;

			p {
				font-size: 1rem;
				user-select: none;
				color: var(--cloud);
				position: relative;
				top: -1px;
			}

			img {
				height: 100%;
				width: 100%;
			}
		}
	}

	.nav-profile-wrapper {
		width: auto;
		min-width: 140px;
		position: absolute;
		right: var(--padding);
		top: calc(var(--nav-height) - 10px);
		border-radius: var(--border-radius);
		opacity: 0;
		pointer-events: none;
		transition: opacity var(--default-transition), top var(--default-transition);
		box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);

		p {
			word-break: break-all;
		}

		&.active {
			top: calc(var(--nav-height) - 4px);
			opacity: 1;
			pointer-events: all;
		}

		.nav-profile-wrapper-header {
			//display:    flex;
			//justify-content: center;
			//flex-wrap:       wrap;
			padding: calc(var(--padding) / 1.5);
			padding-bottom: 0;
			width: auto;
			min-width: 120px;
			max-width: 300px;
			text-align: center;

			.nav-profile-icon {
				height: 80px;
				width: 80px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				border: 1px solid var(--cloud);
				overflow: hidden;

				img {
					height: 100%;
					width: 100%;
				}

				p {
					font-size: 3rem;
					user-select: none;
					color: var(--cloud);
					position: relative;
					top: -1px;
				}

				&.dark,
				&.light {
					background: transparent;
				}
			}
		}

		&.dark {
			background: rgba(15, 15, 15, 0.5);
			backdrop-filter: blur(20px);
			border: 1px solid #303030;
		}

		&.light {
			background: var(--snow);
			backdrop-filter: blur(20px);
			border: 1px solid var(--cloud);
		}

		.nav-profile-item-wrapper {
			padding-bottom: calc(var(--padding) / 1.5);

			.nav-profile-item {
				width: 100%;
				padding: calc(var(--padding) / 2) calc(var(--padding) / 1.5);
				transition: background var(--default-transition);
				cursor: pointer;
				display: flex;
				justify-content: left;
				align-items: center;
				position: relative;

				svg {
					margin-right: calc(var(--padding) / 2);
					position: relative;
					top: 2px;
				}

				p {
					font-weight: 600;
				}

				p.logout {
					color: var(--error) !important;
				}

				&:hover {
					background: rgba(255, 255, 255, 0.1);
				}

				p {
					font-size: .8rem;
					text-align: left;

					&.light {
						color: var(--dusk) !important;
					}

					&.dark {
						color: var(--cloud) !important;
					}
				}
			}
		}
	}
}

.nav.dark {
	//background: var(--gradient-rev);
	//background: #0D0D1F;
	background: var(--nav-dark);
	//border-bottom: 1px solid var(--twilight);
	//border-bottom-style: solid;
	//border-image:        var(--gradient-rev) 100
}

.nav.light {
	//   background: var(--gradient);
	background: var(--snow);
	border-bottom: 1px solid var(--cloud);
}

.nav.active.xs {
	pointer-events: none;
}

.menu {
	height: calc(100vh - var(--nav-height) + 0px);
	width: 0;
	position: absolute;
	z-index: var(--menu-zindex);
	top: calc(var(--nav-height) - 0px);
	left: calc(var(--menu-width) * -1);
	border: none;

	a {
		text-decoration: none;
	}
}

.menu.active {
	left: 0;
	width: var(--menu-width);
	border-right: 1px solid transparent;
}

.menu.dark {
	background: #101010;
	//background:         var(--midnight);
	border-right-color: red;
	border-right: 0px;
	//box-shadow:         20px 0px 30px -14px rgba(0, 0, 0, 0.18);
	//-webkit-box-shadow: 20px 0px 30px -14px rgba(0, 0, 0, 0.18);
	//-moz-box-shadow:    20px 0px 30px -14px rgba(0, 0, 0, 0.18);
}

.menu.light {
	border-right-color: var(--cloud);
	background: var(--snow);
	//box-shadow:         20px 0px 20px -14px rgba(0, 0, 0, 0.09);
	//-webkit-box-shadow: 20px 0px 20px -14px rgba(0, 0, 0, 0.09);
	//-moz-box-shadow:    20px 0px 20px -14px rgba(0, 0, 0, 0.09);
}

.menu.xs .menu-exit {
	position: absolute;
	right: var(--padding);
	top: 50%;
	transform: translateY(-50%);
}

.menu-exit {
	display: none;
}

.menu.active.xs .menu-exit {
	display: block;
}

.menu.xs {
	width: 0;
	left: 0;
	top: 0;
	opacity: 0;
	pointer-events: none;
	border: none;
	height: 100vh;
}

.menu.xs.active {
	width: 100vw;
	opacity: 1;
	pointer-events: all;
}

.menu.xs button.item {
	width: 100vw;
}

.menu button.item {
	height: var(--nav-height)/2;
	width: calc(var(--menu-width) - 1px);
	border-radius: 0;
	justify-content: left;
	font-size: .9rem;
	padding: var(--padding);
	//font-weight:     600;

	svg {
		width: 100%;
		height: 100%;
	}

	div:first-child {
		margin-right: 0.7rem;
	}

	&.focused {
		//background: var(--dusk-lighter) !important;
		background: transparent !important;
		color: var(--logo-purple) !important;
	}
}

.menu button.logout {
	position: absolute;
	bottom: var(--padding);
	left: 0;
	color: var(--error) !important;
}

.menu .disabled {
	pointer-events: none;
}

div.breadcrumbs {
	width: 100vw;
	height: var(--breadcrumbs-height);
	position: absolute;
	left: 0;
	top: var(--nav-height);
	padding: 0 var(--padding);

	a {
		text-decoration: none;
	}
}

div.breadcrumbs.light {
	border-bottom: 1px solid #F0F3FF;
}

div.breadcrumbs.dark {
	background: #131313;
}

div.breadcrumbs.active {
	width: calc(100vw - var(--menu-width));
	left: var(--menu-width);
}

a.breadcrumbs.dark {
	color: var(--cloud);
}

a.breadcrumbs {
	line-height: var(--breadcrumbs-height);
	color: var(--twilight);
	font-size: .9rem;
	font-weight: 600;
	user-select: none;
}

a.breadcrumbs:after {
	content: ">";
	margin: 0 calc(var(--padding) * 0.7);
	position: relative;
	top: -2px;
	font-size: .7rem;
	font-weight: 700;
}

a.breadcrumbs:last-child {
	color: var(--steel);
	font-weight: 500;
	pointer-events: none;
}

a.breadcrumbs:last-child:after {
	display: none;
}

.content {
	//padding:  1rem;
	width: 100vw;
	height: calc(100vh - var(--nav-height) - var(--breadcrumbs-height));
	position: absolute;
	z-index: var(--content-zindex);
	left: 0;
	top: calc(var(--nav-height) + var(--breadcrumbs-height));
	overflow: auto;
}

.content.dark {
	background: var(--content-bg);
}

.content.active {
	width: calc(100vw - var(--menu-width));
	left: var(--menu-width);
	border-top-left-radius: var(--border-radius);
}

.content.active.xs {
	pointer-events: none;
}

.content.xs {
	width: 100vw;
}

.content.xs.active {
	display: none;
}

.logo {
	height: 45px;
	width: 45px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: margin var(--default-transition);
	stroke-width: 2px;
}

.logo.dark {
	//fill:   url("#gradient") !important;
	//stroke: url("#gradient") !important;
	fill: var(--twilight-darker) !important;
	stroke: var(--twilight-darker) !important;
	stroke-width: 5px;
}

.logo.light {
	fill: var(--snow) !important;
	stroke: var(--snow) !important;
}

button.hamburger {
	position: relative;
	//top:         50%;
	//transform:   translateY(-50%);
	margin-left: var(--padding);
	padding: 0 !important;
}

div.menu-profile.dark {
	border-bottom: 1px solid var(--twilight);
}

div.menu-profile {
	height: var(--menu-profile-height);
	width: 100%;
	display: flex;
	justify-content: left;
	border-bottom: 1px solid var(--cloud);

	div.menu-profile-text-wrapper {
		position: relative;
		height: var(--menu-profile-height);
		width: calc(100% - var(--menu-profile-height));
	}

	div.menu-profile-icon {
		position: relative;
		height: var(--menu-profile-height);
		width: var(--menu-profile-height);
		padding: var(--padding);
	}

	div.menu-profile-icon-circle {
		height: 100%;
		width: 100%;
		border-radius: 50%;
		position: relative;
	}

	div.menu-profile-icon-circle.light {
		background: var(--dusk);
	}

	div.menu-profile-icon-circle.dark {
		background: var(--cloud);
	}

	p.menu-profile-icon-circle-abbr {
		position: absolute;
		top: 49%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-weight: 600;
		font-size: 1rem;
	}

	p.menu-profile-icon-circle-abbr.light {
		color: var(--cloud);
	}

	p.menu-profile {
		font-weight: 600;
		line-height: 1;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -0.5rem;
		font-size: .9rem;
	}

	p.menu-profile.light {
		color: var(--dusk);
	}

	p.menu-profile.dark {
		color: var(--cloud);
	}
}