.wrapper {
  .input-wrapper {
	display: flex;
	width:   100%;
  }

  label {
	position:      relative;
	display:       inline-block;
	margin-bottom: .2rem;
	top:           -3px;
	font-size:     .9rem;
	font-weight:   600;
	line-height:   1;

	&.dark {
	  color: var(--steel);
	}

	&.light {
	  color:       var(--bluesteel-dark);
	  font-weight: 500;
	}
  }
}

.icon-wrapper {
  height:                    auto;
  width:                     auto;
  display:                   inline-flex;
  justify-content:           center;
  align-items:               center;
  padding-left:              calc(var(--padding) / 1.3);
  border-top-left-radius:    var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.icon-wrapper.light {
  border:       1px solid var(--steel);
  border-right: 0;
}

.icon-wrapper.dark {
  background:   var(--input-bg);
  border-right: none;

  svg {
    color: var(--dirty-snow);
  }
}

.input {
  padding:        calc(var(--padding)) var(--padding);
  border-radius:  var(--border-radius);
  outline:        none;
  border:         none;
  font-size:      .9rem;
  letter-spacing: .5px;
  font-weight:    500;
  width:          100%;
}

.input.icon {
  border-top-left-radius:    0;
  border-bottom-left-radius: 0;
  border-left:               none !important;
  padding-left:              calc(var(--padding) / 1.3);
}

.input.light.icon {
  border-left: none;
  background:  none;
}

.input.dark {
  background: var(--input-bg);
  color:      #FFFFFF;
  //chrome auto-complete
  color-scheme: dark;
}

.input.dark::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:          var(--input-placeholder-color);
  opacity:        1; /* Firefox */
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.dark:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:          #ADAAF1;
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.dark::-ms-input-placeholder { /* Microsoft Edge */
  color:          #ADAAF1;
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.light {
  border: 1px solid var(--steel);
}

.input.light::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:          var(--steel);
  opacity:        1; /* Firefox */
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.light:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:          var(--steel);
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.light::-ms-input-placeholder { /* Microsoft Edge */
  color:          var(--steel);
  font-weight:    500;
  font-family:    Raleway, serif;
  letter-spacing: .5px;
}

.input.disabled {
  opacity: .2;
  cursor:  not-allowed;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  // accent-color: var(--primary);
}
