
div.item {
  position:      absolute;
  width:         300px;
  margin:        1rem;
  transition:    top var(--default-transition), bottom var(--default-transition), right var(--default-transition), left var(--default-transition), opacity var(--default-transition);
  right:         0;
  bottom:        -20rem;
  opacity:       0;
  user-select:   none;
  padding:       0.5rem;
  border-radius: var(--border-radius);
}

div.item.light {
  color:            var(--cloud);
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter:  blur(20px);
  border:           1px solid var(--cloud);
}

div.item.dark {
  color:            var(--cloud);
  background-color: rgba(62, 60, 119, 0.8);
  backdrop-filter:  blur(20px);
  border:           1px solid var(--twilight);
}

div.item.leave {
  right:   -20rem;
  opacity: 0 !important;
}

div.item.active {
  bottom:         0;
  opacity:        1;
  pointer-events: all;
  user-select:    auto;
}

div.item.active.light {
  box-shadow:         -1px 20px 40px -14px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -1px 20px 40px -14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:    -1px 20px 40px -14px rgba(0, 0, 0, 0.2);
}

div.item.active.dark {
  box-shadow:         -1px 20px 40px -14px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: -1px 20px 40px -14px rgba(0, 0, 0, 0.6);
  -moz-box-shadow:    -1px 20px 40px -14px rgba(0, 0, 0, 0.6);
}

div.item.active-queued {
  bottom:  0rem;
  opacity: 0.7;
}

div.item.disabled {
  pointer-events: none;
}

.header {
  position:        relative;
  display:         flex;
  justify-content: left;
  flex-direction:  row;
  align-items:     center;
  padding-bottom:  0.25rem;

  button {
	position: absolute;
	top:      0;
	right:    0;
  }
}

.event-children {
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;

  p {
	line-height: normal;
  }
}

.event-close.light {
  color: var(--twilight)
}

.event-close.dark {
  color: var(--cloud)
}

.content-wrapper {
  width:          auto;
  height:         100%;
  display:        flex;
  flex-direction: row;
}

.content {
  width:          100%;
  display:        flex;
  flex-direction: column;
}

.event-icon {
  display:        flex;
  flex-direction: row;
  align-items:    center;
  margin-right:   0.5rem;
}

.event-icon > svg {
  color: var(-success);
}