.header {
  line-height: 1.6rem;
  font-weight: 500;
}

.light {
  color: var(--twilight);
}

.dark {
  color: var(--steel);
}