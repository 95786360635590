:root {
  // Colors
  --nav-dark: #0A0A0A;
  --primary: #564CD9;
  --secondary: #696969;
  --success: #53DA49;
  --warning: #FF9F6C;
  --error: #FF5959;

  --aws-orange: #FF9900;

  --200: #B4AFF5;
  --300: #968EEF;
  --400: #857CEF;
  --500: #6B61E7;

  --content-bg: #161616;

  --input-bg: #1A1A1A;
  --input-placeholder-color: #696969;

  --footer-text-color: #808080;

  --midnight: #1A1A1A;
  --dusk-darker: #202020;
  --dusk: #303030;
  --dusk-lighter: #353535;
  --twilight-darker: #404040;
  --twilight: #505050;
  --bluesteel: #7B90BE;
  --steel: #8D98AF;
  --cloud: #C9D9F8;
  --dirty-snow: #EAECF8;
  --snow: #F8F9FF;

  //--gradient:              linear-gradient(90deg, var(--logo-purple) 0%, var(--logo-blue) 100%);
  //--gradient-border: linear-gradient(var(--logo-purple), var(--logo-blue));
  //--gradient-opaque:       linear-gradient(90deg, rgba(198, 108, 255, 0.2) 0%, rgba(140, 221, 255, 0.5) 100%);
  //--gradient-rev:          linear-gradient(90deg, var(--logo-blue) 0%, var(--logo-purple) 100%);
  //--gradient-blue-fade:    linear-gradient(90deg, rgba(198, 108, 255, 0.25) 0%, rgba(140, 221, 255, 0.25) 100%);
  //--gradient-blue-fade-1:  linear-gradient(90deg, rgba(198, 108, 255, 0.05) 0%, rgba(140, 221, 255, 0.05) 100%);

  // Formatting
  --padding: 1rem;
  --border-radius: 3px;

  // Shell Formatting
  --nav-height: 52px;
  --menu-width: 230px;
  --breadcrumbs-height: 52px;
  --menu-profile-height: 65px;

  // Flexbox Grid Config
  --flexbox-grid-columns: "12";

  // Breakpoints
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;

  // Transitions
  --default-transition: .3s ease;
  --cubic-transition: .1s cubic-bezier(.1, .5, .8, .98);

  // Z-indexes
  --nav-zindex: 11;
  --menu-zindex: 10;
  --footer-zindex: 9;
  --content-zindex: 8;

  ::-moz-selection {
    /* Code for Firefox */
    color: #FFFFFF;
    background: var(--primary);
  }

  ::selection {
    color: #FFFFFF;
    background: var(--primary);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Exo 2", sans-serif;
  //font-family:             "Comfortaa", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-overflow: clip !important;
}

.dark {
  color: var(--cloud);
}

.light {
  color: var(--dusk);
}

html {
  body {
    overflow: hidden;
  }

  body:before {
    content: "xs";
    display: none;
    visibility: hidden;
  }

  body:after {
    content: var(--flexbox-grid-columns);
    display: none;
    visibility: hidden;
  }

  a {
    //text-decoration: none;
    color: var(--primary);
  }

  ol,
  ul {
    list-style: normal inside;

    ul {
      margin-left: var(--padding);
      list-style: inside;
    }
  }

  @media (min-width: 576px) {
    body:before {
      content: "sm";
    }
  }

  @media (min-width: 768px) {
    body:before {
      content: "md";
    }
  }

  @media (min-width: 992px) {
    body:before {
      content: "lg";
    }
  }

  @media (min-width: 1200px) {
    body:before {
      content: "xl";
    }
  }
}

// MUI Overrides
.MuiDataGrid-iconSeparator {
  display: none !important;
}

.MuiButtonBase-root {
  font-family: "IBM Plex Sans", sans-serif !important;
}

.MuiDataGrid-root {
  font-size: .8rem !important;
}

.light.MuiDataGrid-root {
  border-color: var(--cloud) !important;
  // border-radius: 0 !important;
  border-width: 1px !important;
  //border:      none !important;
  font-weight: 500 !important;
  color: #1C1B3B !important;
  font-family: "IBM Plex Sans", sans-serif;

  .MuiDataGrid-cell {
    border-color: var(--cloud) !important;
    font-family: "IBM Plex Sans", sans-serif;
    outline: none !important;
  }

  .MuiDataGrid-columnHeader:focus {
    //outline:     none !important;
    user-select: none !important;
  }

  .Mui-selected {
    // background: rgba(140, 221, 255, 0.2) !important;
  }

  .MuiDataGrid-columnHeaders {
    border-color: var(--cloud) !important;
    border-width: 1px !important;
    // background:   var(--snow) !important;

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 600 !important;
      font-family: "IBM Plex Sans", sans-serif;
      //outline:     none !important;
    }
  }

  .MuiDataGrid-footerContainer {
    border-top: none !important;
    // background:                 var(--snow) !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  svg {
    //fill: var(--dusk);
    // opacity: 1 !important;
  }

  .MuiToolbar-root {
    color: var(--dusk) !important;
    font-family: "IBM Plex Sans", sans-serif !important;

    p {
      font-family: "IBM Plex Sans", sans-serif !important;
    }

    svg {
      //fill: var(--dusk) !important;
    }
  }
}

.MuiDataGrid-topContainer .MuiDataGrid-container--top {
  border-color: var(--dusk-darker) !important;
  background-color: transparent !important;
}

.dark.MuiDataGrid-root {
  border-color: var(--dusk-darker) !important;
  //border-radius: 0 !important;
  border-width: 1px !important;
  //border:       none !important;
  font-weight: 500 !important;
  //background:  var(--midnight) !important;
  color: var(--cloud) !important;
  font-family: "IBM Plex Sans", sans-serif;

  .MuiDataGrid-cell {
    border-color: var(--dusk-darker) !important;
    border-width: 1px !important;
    font-family: "IBM Plex Sans", sans-serif;
    outline: none !important;
    //border:      none !important;
  }

  .MuiDataGrid-columnHeader {
    background-color: transparent !important;
  }

  .MuiDataGrid-columnHeader:focus {
    //outline:     none !important;
    user-select: none !important;
  }

  .MuiDataGrid-cellContent {
    color: #B5C5E5 !important;
  }

  .Mui-selected {
    background: rgba(255, 255, 255, 0.04) !important;
  }

  .MuiDataGrid-columnHeaders {
    border-color: var(--dusk-darker) !important;
    border-width: 1px !important;
    // background:   var(--snow) !important;
    //border: none !important;

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 600 !important;
      font-family: "IBM Plex Sans", sans-serif;
      //outline:     none !important;
    }
  }

  .MuiDataGrid-footerContainer {
    border-top: none !important;
    // background:                 var(--snow) !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  svg {
    //fill: var(--cloud);
    // opacity: 1 !important;
  }

  .MuiToolbar-root {
    color: var(--cloud) !important;
    font-family: "IBM Plex Sans", sans-serif !important;

    p {
      font-family: "IBM Plex Sans", sans-serif !important;
    }

    svg {
      //fill: var(--cloud) !important;
    }
  }
}

// Code blocks TODO: Remove from index and put into module
pre {
  border-radius: 5px;
  overflow-x: scroll;
  font-size: 0.8rem !important;
  //max-height:    700px;
  //max-width:     1000px;
  padding: calc(var(--padding));
}

pre.light {
  background: var(--twilight);
  color: var(--cloud) !important;
}


pre.dark {
  background: var(--dusk) !important;
  color: var(--cloud) !important;
}

code,
code span {
  font-family: "Source Code Pro", serif !important;
}

.monaco-editor {
  border-radius: 10px;

  span {
    font-family: "JetBrains Mono", sans-serif !important;
    //letter-spacing: .2px;
  }
}

.MuiAutocomplete-Popper {
  white-space: unset !important;
  word-break: break-all !important;
}

.MuiPopperUnstyled-root {
  white-space: unset !important;
  word-break: break-all !important;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: var(--input-background-dark) !important;
  border-radius: var(--border-radius) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--input-background-dark) !important;
  border-radius: var(--border-radius) !important;
}

// ************ SCROLLBAR ************

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

// ::-webkit-scrollbar-track {
//     background: vars.$light-bg-header;
// }

// ::-webkit-scrollbar-thumb {
//     background: vars.$dark-bg-footer;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: vars.$dark-bg-tertiary;
// }


// ::-webkit-scrollbar-track {
//     background: vars.$dark-bg-secondary;
// }

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f6bf3a4;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}