.btn {
  position:        relative;
  display:         inline-flex;
  justify-content: center;
  align-items:     center;
  border:          none;
  letter-spacing:  .5px;
  overflow:        hidden;
  user-select:     none;
  cursor:          pointer;
  border-radius:   var(--border-radius);
  font-weight:     500;
  transition:      background var(--cubic-transition);

  p, svg {
	position: relative;
	z-index:  1;
  }

  svg {
	// TODO: FIX FOR SAFARI
	//height: 100%;
	//width:  100%;
  }
}

svg.spinner {
  margin-right: .3rem;
  height:       auto;
  width:        auto;

  &.dark {
	fill: var(--dirty-snow)
  }

  &.light {
	fill: var(--dusk);
  }
}

.circle {
  border-radius:  50%;
  position:       absolute;
  opacity:        0;
  transform:      scale(0);
  pointer-events: none;
  transition:     opacity .3s ease-out, transform 1s;
}

.circle.disable-ripple-animation {
  transition: opacity .3s ease-out;
}

// Variants
.btn.sm {
  padding:   calc(var(--padding) / 1.5) calc(var(--padding) / 1);
  font-size: .8rem;

  .icon {
	height:       15px;
	width:        15px;
	margin-right: .3rem;
  }
}

.btn.md {
  padding:   var(--padding) calc(var(--padding) * 1.5);
  font-size: 1rem;

  .icon {
	height:       20px;
	width:        20px;
	margin-right: .5rem;
  }
}

.btn.lg {
  padding:   calc(var(--padding) * 1.25) calc(var(--padding) * 1.5);
  font-size: 1.3rem;

  .icon {
	height:       22px;
	width:        22px;
	margin-right: .6rem;
  }
}

// Filled
.filled.light {
  color: var(--bg-dark);

  .circle {
	background: #FFFFFF;
  }
}

.filled.dark {
  color: var(--bg-dark);

  .circle {
	background: var(--cloud);
  }
}

// .btn:active {
//     top: 2px;
// }

// .primary.outline:focus {
//     outline-offset: 0;
//     outline-color:  var(--primary);
// }
//
// .secondary.outline:focus {
//     outline-offset: 0;
//     outline-color:  var(--secondary);
// }
//
// .success.outline:focus {
//     outline-offset: 0;
//     outline-color:  var(--success);
// }
//
// .warning.outline:focus {
//     outline-offset: 0;
//     outline-color:  var(--warning);
// }
//
// .error.outline:focus {
//     outline-offset: 0;
//     outline-color:  var(--error);
// }

.filled.primary {
  background:  var(--primary);
  //border:     1px solid var(--logo-purple);
  color:       var(--cloud);
  font-weight: 600;

  &.disabled {
	&:hover {
	  //background: var(--logo-purple-opaque) !important;
	}
  }

  &:hover {
	//background: var(--logo-purple-opaque-4);
  }

  //&.dark, &.light {
  //
  //}
}

.filled.secondary.dark {
  background: #FFFFFF;
  color:      var(--secondary);
}

.filled.secondary {
  background: var(--secondary);
  color:      #FFFFFF;
}

.filled.success {
  background: var(--success);
}

.filled.warning {
  background: var(--warning);
}

.filled.error {
  background: var(--error);
  color:      #FFFFFF;
}

// Outlined
.outlined.light {
  background: none;
  border:     1px solid var(--primary);
  color:      var(--primary);

  .circle {
	background: var(--primary);
  }
}

.outlined.dark {
  background: none;
  border:     1px solid var(--primary);
  color:      var(--primary);

  .circle {
	background: var(--primary);
  }
}

.outlined.primary {
  border-color: var(--primary);
  color:        var(--primary);

  .circle {
	background: var(--primary);
  }
}

.outlined.secondary.light {
  border-color: var(--secondary);
  color:        var(--secondary);

  .circle {
	background: var(--secondary);
  }
}

.outlined.secondary.dark {
  border-color: #FFFFFF;
  color:        #FFFFFF;

  .circle {
	background: #FFFFFF;
  }
}

.outlined.success {
  border-color: var(--success);
  color:        var(--success);

  .circle {
	background: var(--success);
  }
}

.outlined.warning {
  border-color: var(--warning);
  color:        var(--warning);

  .circle {
	background: var(--warning);
  }
}

.outlined.error {
  border-color: var(--error);
  color:        var(--error);

  .circle {
	background: var(--error);
  }
}

// Transparent
.transparent.light {
  background: none;
  color:      var(--primary);

  .circle {
	background: var(--primary);
  }
}

.transparent.dark {
  background: none;
  color:      var(--primary);

  .circle {
	background: var(--primary);
  }
}

.transparent.primary {
  color: var(--primary);

  .circle {
	background: var(--primary);
  }
}

.transparent.secondary {
  color: var(--secondary);

  .circle {
	background: var(--primary);
  }
}

.transparent.secondary.dark {
  color: var(--cloud);

  .circle {
	background: var(--primary);
	//background: var(--dusk-lighter);
  }
}

.transparent.secondary.light {
  color: var(--dusk);

  .circle {
	background: var(--gradient);
  }
}

.transparent.success {
  color: var(--success);

  .circle {
	background: var(--success);
  }
}

.transparent.warning {
  color: var(--warning);

  .circle {
	background: var(--warning);
  }
}

.transparent.error {
  color: var(--error);

  .circle {
	background: var(--error);
  }
}

// Circle States
.active {
  opacity: .3;
}

.expand {
  transform: scale(4) !important;
}

.disabled {
  opacity: .35;
  cursor:  not-allowed;
  //filter:  grayscale(1);
}

.focused {
  background: var(--logo-purple-opaque) !important;

  //.circle {
  //opacity: 0;
  //}
}