div.event-container {
  background: transparent;
  position:   fixed;
  z-index:    10000;
  transition: all .5s;
  bottom:     0;
  right:      0;
  width:      284px;
  overflow:   visible;
}
