// .table.light {
//
//     background: red;
//
//     // MUI Overrides
//     .MuiDataGrid-iconSeparator {
//         display: none !important;
//     }
//
//     .MuiButtonBase-root {
//         font-family: "IBM Plex Sans", sans-serif !important;
//     }
// }
//
// .dark {
//     color: red;
// }
//
// .light.MuiDataGrid-root {
//     // border-color:  var(--cloud) !important;
//     // border-radius: 0 !important;
//     // border-width:  1px !important;
//     border:      none !important;
//     font-weight: 500 !important;
//     color:       #1C1B3B !important;
//     font-family: "IBM Plex Sans", sans-serif;
//     background:  red !important;
//
//     .MuiDataGrid-cell {
//         border-color: var(--cloud) !important;
//         font-family:  "IBM Plex Sans", sans-serif;
//         outline:      none !important;
//     }
//
//     .MuiDataGrid-columnHeader:focus {
//         outline:     none !important;
//         user-select: none !important;
//     }
//
//     .MuiDataGrid-columnHeaders {
//         border-color: var(--cloud) !important;
//         border-width: 1px !important;
//         // background:   var(--snow) !important;
//
//         .MuiDataGrid-columnHeaderTitle {
//             font-weight: 600 !important;
//             font-family: "IBM Plex Sans", sans-serif;
//             outline:     none !important;
//         }
//     }
//
//     .MuiDataGrid-footerContainer {
//         border-top:                 none !important;
//         // background:                 var(--snow) !important;
//         border-bottom-left-radius:  5px !important;
//         border-bottom-right-radius: 5px !important;
//     }
//
//     svg {
//         fill: var(--dusk);
//         // opacity: 1 !important;
//     }
// }

.tableNoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .text {
    font-size: 1rem;
    font-weight: 600;
  }
}