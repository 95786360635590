.shelf {
  position:       fixed;
  z-index:        1000;
  top:            var(--nav-height);
  //top:            0;
  height:         calc(100vh - var(--nav-height));
  pointer-events: none;
  opacity:        0;
  transition:     opacity var(--default-transition), right var(--default-transition);
  padding:        var(--padding);

  &.dark {
	background:  var(--dusk);
	border-left: 1px solid var(--twilight);
  }

  &.light {
	background: var(--snow);
  }

  &.active {
	opacity:        1;
	right:          0;
	pointer-events: all;
  }
}

.alt-exit {
  position:       fixed;
  z-index:        999;
  background:     black;
  width:          100vw;
  height:         100vh;
  left:           0;
  top:            0;
  opacity:        0;
  pointer-events: none;
  cursor:         pointer;
  transition:     opacity var(--default-transition);
}

.alt-exit.active {
  opacity:        .15;
  pointer-events: all;
}

.xs {
  width: 100vw;
  right: -30px;
}

.sm {
  width: 100vw;
  right: -30px;
}

.md {
  width: 80vw;
  right: -30px;
}

.lg {
  width: 60vw;
  right: -30px;
}

.xl {
  width: 50vw;
  right: -30px;
}