.shelf {
  position:       fixed;
  z-index:        1000;
  opacity:        0;
  pointer-events: none;
  overflow-y:     auto;
  transition:     bottom var(--default-transition), opacity var(--default-transition);
  bottom:         -3rem;
}

.shelf.dark {
  background:         #1F1F1F;
  box-shadow:         -1px -20px 40px -14px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -1px -20px 40px -14px rgba(0, 0, 0, 0.4);
  -moz-box-shadow:    -1px -20px 40px -14px rgba(0, 0, 0, 0.4);
}

.shelf.light {
  background:         #FFFFFF;
  box-shadow:         -1px -20px 40px -14px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -20px 40px -14px rgba(0, 0, 0, 0.1);
  -moz-box-shadow:    -1px -20px 40px -14px rgba(0, 0, 0, 0.1);
}

.shelf.active {
  pointer-events: all;
  opacity:        1;
  bottom:         0;
}

.shelf div.header {
  width:           100%;
  position:        sticky;
  top:             0;
  padding:         calc(var(--padding) * 0.5);
  display:         flex;
  justify-content: right;

  button.shelf-exit {
	height: 100%;
  }
}

.handle {
  width:      100%;
  height:     4px;
  position:   absolute;
  top:        0;
  cursor:     n-resize;
  transition: border-width .1s ease;
}

.container {
  height:   100%;
  width:    100%;
  overflow: auto;
}

.handle.dark {
  //background: var(--twilight);
  height:     20px;
  border-top: 2px solid var(--twilight);
}

.handle.light {
  //background: var(--dirty-snow);
  height:     20px;
  border-top: 1px solid var(--cloud);
  //border-top: 4px solid var(--dirty-snow);
}

.content {
  margin-top: -20px;
  width:      100%;
}

.xs {
}

.sm {
}

.md {
}

.lg {
}

.xl {
}