.footer {
  width:           100%;
  height:          50px;
  display:         flex;
  justify-content: right;
  align-items:     center;
  position:        fixed;
  z-index:         var(--footer-zindex);
  bottom:          0;
  right:           0;
  padding-right:   var(--padding);
  pointer-events: none;

  p {
	text-align:  right;
	font-size:   .9rem;
	user-select: none;
	opacity:     .3;
	font-weight: 500;

	&.dark {
	  color: var(--footer-text-color);
	}

	&.light {
	  color: var(--cloud);
	}
  }
}