.row {
  position:        relative;
  display:         flex;
  flex-flow:       row wrap;
  justify-content: left;
  width:           100%;
}

.col {
  position:       relative;
  opacity:        0;
  // pointer-events: none;
  //transition:     .2s ease opacity;
}

.padding {
  padding: var(--padding);
}