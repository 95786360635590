.divider {
  width:  100%;
  height: 1px;
  margin: var(--padding) 0;
}

.divider.light {
  background: var(--dirty-snow);
}

.divider.dark {
  background: transparent;
}