div.bar-metric {
  width: 100%;

  div.bar-metric-container {
	height:          15px;
	width:           100%;
	border-radius:   var(--border-radius);
	display:         flex;
	justify-content: left;

	div.data-point {
	  height:     100%;
	  transition: width var(--default-transition), opacity var(--default-transition);
	  cursor:     pointer;
	}

	//div.data-point:hover {
	//  opacity: .8;
	//}

	div.data-point:first-child {
	  border-top-left-radius:    50px !important;
	  border-bottom-left-radius: 50px !important;
	}

	div.data-point:last-child {
	  border-top-right-radius:    50px !important;
	  border-bottom-right-radius: 50px !important;
	}

	div.data-label {
	  height:          100%;
	  transition:      width var(--default-transition);
	  display:         flex;
	  justify-content: center;
	  align-items:     center;

	  p {
		font-size:   .6rem;
		line-height: normal;
		user-select: none;
	  }

	  p.dark {
		color: var(--bluesteel);
	  }

	  p.light {
		color: var(--twilight);
	  }
	}
  }
}

