.code {
  position: relative;

  .button-container {
	position: absolute;
	top:      .5rem;
	right:    .5rem;

	button {
	  font-size: .9rem;
	  padding:   .6rem;
	}
  }
}