.spinner {
  position:                  absolute;
  top:                       50%;
  left:                      50%;
  transform:                 translate(-50%, -50%);
  animation:                 spin;
  animation-duration:        .8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.light {
    fill: var(--twilight);
  }

  &.dark {
    fill: var(--bluesteel);
  }
}

.spinner-relative {
  position:                  relative;
  animation:                 spin;
  animation-duration:        .8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.light {
    fill: var(--twilight);
  }

  &.dark {
    fill: var(--bluesteel);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
