.box {
  padding: var(--padding);
}

//.box.xs {
//  padding: calc(var(--padding) * 0.3875);
//}
//
//.box.sm {
//  padding: calc(var(--padding) * 0.3875);
//}
//
//.box.md {
//  padding: calc(var(--padding) * 0.5);
//}

.light {
  color: #1A1A1A;
}

.dark {
  color: #E3E3E3;
}

.disable-horizontal-padding {
  padding-right: 0;
  padding-left:  0;
}

.disable-vertical-padding {
  padding-top:    0;
  padding-bottom: 0;
}

.disabled {
  opacity:        .5;
  cursor:         not-allowed;
  pointer-events: none;
}